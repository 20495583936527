import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Async from 'react-select/async';
import FieldTemplate from '../../FieldTemplate';

import DropdownIcon from '../../../../../public/static/icons/navigation-chevron-down.svg';

class SelectFieldAsync extends React.Component {
  // Reference to the select field.
  selectRef = null;

  componentDidMount() {
    const { autoFill = true } = this.props;
    // Preventing autofilling.
    if (!autoFill) {
      this.selectRef.inputRef.autocomplete = 'new-password';
    }
  }

  // Custom template for "SelectContainer" component of react-select library.
  SelectContainer = ({ children, hasValue, selectProps, ...props }) => {
    const classes = [];

    if (hasValue) {
      classes.push('has-value');
    }

    if (selectProps.menuIsOpen) {
      classes.push('opened');
    }

    return (
      <components.SelectContainer {...props} className={classes.join(' ')}>
        {children}
      </components.SelectContainer>
    );
  };

  // Special field adapter for react-final-form library which connects
  // react-select component to the form.
  SelectFieldAsyncAdapter = ({
    input: { value, onChange, onFocus, onBlur },
    meta,
    onOptionSelect = () => {},
    loadOptions = null,
    ...rest
  }) => (
    <Async
      cacheOptions
      defaultOptions
      onChange={(selectedOption) => {
        onChange(selectedOption);
        onOptionSelect(selectedOption);
      }}
      value={value}
      blurInputOnSelect
      ref={(ref) => {
        this.selectRef = ref;
      }}
      onFocus={onFocus}
      onBlur={onBlur}
      loadOptions={loadOptions}
      {...rest}
    />
  );

  render() {
    const { options = [], label, name, ...props } = this.props;

    return (
      <FieldTemplate
        component={this.SelectFieldAsyncAdapter}
        type="select"
        name={name}
        options={options}
        label={label}
        onLabelClick={() => {
          this.selectRef.onMenuOpen();
          this.selectRef.focus();
        }}
        placeholder=" "
        components={{
          SelectContainer: this.SelectContainer,
          DropdownIndicator: () => <DropdownIcon />,
        }}
        className="select"
        classNamePrefix="select"
        /* eslint-disable-next-line react/style-prop-object */
        style="normal"
        {...props}
      />
    );
  }
}

SelectFieldAsync.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  loadOptions: PropTypes.func,
  onOptionSelect: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  autoFill: PropTypes.bool,
};

export default SelectFieldAsync;
