import React from 'react';
import { Field, useForm } from 'react-final-form';
import FieldError from '../FieldError';
import FieldInfo from '../FieldInfo';

import styles from './index.module.scss';

type Props = {
  options: RadioFieldOptions[];
  label?: string;
  name: string;
  isRequired?: boolean;
  info?: string;
  onKeyDown?: (
    e: React.KeyboardEvent,
    item: RadioFieldOptions,
    mutateFormValue: MutateFormCallback,
  ) => void;
};

type RadioFieldOptions = {
  value?: string;
  label?: string;
};

type MutateFormCallback = (name: string, value: string) => void;

const RadiosField = ({
  options,
  label = '',
  name,
  isRequired = false,
  info = '',
  onKeyDown,
  ...props
}: Props) => {
  const form = useForm();

  const handleKeyDown = (e: React.KeyboardEvent, item: RadioFieldOptions) => {
    if (onKeyDown) {
      onKeyDown(e, item, form.mutators.setValue);
      return;
    }

    const enter = 13;
    const space = 32;
    if ((e.keyCode === enter || e.keyCode === space) && form.mutators.setValue) {
      e.preventDefault();
      form.mutators.setValue(name, item.value);
    }
  };

  const componentCssName = 'field-type-radios';

  return (
    <Field name={name} subscription={{ error: true, touched: true }}>
      {({ meta: { error, touched } }) => (
        <div
          className={`${styles[componentCssName]} field-${name} ${error && touched ? styles['field-type-radios--error'] : ''}`}
          role="group"
          aria-labelledby={`radios_${name}`}
        >
          <label
            htmlFor={`radios_${name}`}
            id={`radios_${name}`}
            className={label ? styles[componentCssName + '__label'] : 'd-none'}
          >
            {label}
            <span className={styles[componentCssName + '__label-validation']}>
              {isRequired ? ' (required)' : ' (optional)'}
            </span>
          </label>

          <div data-component="radios_values">
            {options.map((item) => (
              <Field
                name={name}
                key={`${name}_${item.value}`}
                render={({ input }) => (
                  <div
                    className={[
                      styles[componentCssName + '__item'],
                      item.value === input.value
                        ? styles[componentCssName + '__item--selected']
                        : '',
                    ]
                      .filter(Boolean)
                      .join(' ')}
                  >
                    <label
                      className={styles[componentCssName + '__item-label']}
                      htmlFor={`${name}_${item.value}`}
                      tabIndex={0}
                      onKeyDown={(e) => handleKeyDown(e, item)}
                    >
                      <Field
                        component="input"
                        className={`${styles[componentCssName + '__item-input']}`}
                        type="radio"
                        name={name}
                        value={item.value}
                        id={`${name}_${item.value}`}
                        {...props}
                      />

                      <span className={styles[componentCssName + '__item-indicator']} />

                      <div className={styles[componentCssName + '__item-label-label']}>
                        {item.label}
                      </div>
                    </label>
                  </div>
                )}
              />
            ))}
          </div>

          <FieldInfo name={name} info={info} />
          <FieldError name={name} />
        </div>
      )}
    </Field>
  );
};

export default RadiosField;
