import { EMAIL_EXPRESSION } from './constants';
import { formatDate } from './datetime';

/**
 * Helper function to compose multiple validators into one.
 *
 * @param validators
 *   Array of validator functions.
 */
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);

/**
 * Field validator.
 * Checks that the field is required.
 *
 * @param errorMessage
 *   Error message.
 */
export const required = (errorMessage) => (value) => {
  const message = errorMessage || 'Required';
  if (Array.isArray(value)) {
    return value.length ? undefined : message;
  }

  if (value === true || value === false) {
    return value ? undefined : message;
  }

  if (typeof value === 'object') {
    return value && value.value.trim() ? undefined : message;
  }

  // We need trim the input, otherwise whitespace-only values will be validated
  return value && value.trim() ? undefined : message;
};

/**
 * Field validator.
 * Makes sure that the field has a numeric value.
 *
 * @param errorMessage
 *   Error message.
 */
export const mustBeNumber = (errorMessage) => (value) =>
  value && Number.isNaN(Number(value)) ? errorMessage || 'Please use numbers only' : undefined;

/**
 * Field validator.
 * Makes sure that the field value is greater than the given min value.
 *
 * @param min
 *   Minimal value of the numeric field.
 * @param errorMessage
 *   Error message.
 */
export const minValue = (min, errorMessage) => (value) =>
  Number.isNaN(Number(value)) || value >= min || min === 0
    ? undefined
    : errorMessage || `Should be greater than ${min - 1}`;

/**
 * Field validator.
 * Makes sure that the field value is less than the given max value.
 *
 * @param max
 *   Miaximal value of the numeric field.
 * @param errorMessage
 *   Error message.
 */
export const maxValue = (max, errorMessage) => (value) =>
  Number.isNaN(Number(value)) || value <= max
    ? undefined
    : errorMessage || `Should be less than ${max + 1}`;

/**
 * Field validator.
 * Makes sure that the field value contain more words than the given min value.
 *
 * @param min
 *   Minimal words of the text field.
 * @param errorMessage
 *   Error message.
 */
export const minWords = (min, errorMessage) => (value) =>
  (value && value.split(' ').filter((el) => !!el).length >= min) || min === 0
    ? undefined
    : errorMessage || `Please enter more than ${min - 1} words`;

/**
 * Field validator.
 * Makes sure that the field value contain less words than the given max value.
 *
 * @param max
 *   Miaximal words of the text field.
 * @param errorMessage
 *   Error message.
 */
export const maxWords = (max, errorMessage) => (value) =>
  typeof value === 'undefined' || value.split(' ').filter((el) => !!el).length <= max
    ? undefined
    : errorMessage || `Please enter less than ${max + 1} words`;

/**
 * Field validator.
 * Makes sure that the field value contain more characters than the given min value.
 *
 * @param min
 *   Minimal characters of the text field.
 * @param errorMessage
 *   Error message.
 */
export const minCharacters = (min, errorMessage) => (value) =>
  (value && value.length >= min) || min === 0
    ? undefined
    : errorMessage || `Please enter more than ${min - 1} characters`;

/**
 * Field validator.
 * Makes sure that the field value contain less characters than the given max value.
 *
 * @param max
 *   Miaximal characters of the text field.
 * @param errorMessage
 *   Error message.
 */
export const maxCharacters = (max, errorMessage) => (value) =>
  typeof value === 'undefined' || value.length <= max
    ? undefined
    : errorMessage || `Please enter less than ${max + 1} characters`;

/**
 * Field validator.
 * Makes sure that the field value contains at least certain amount of digits.
 *
 * @param min
 *   Minimal amount of digits in the field.
 * @param errorMessage
 *   Error message.
 */
export const minDigits = (min, errorMessage) => (value) =>
  !value || typeof value !== 'string' || value.replace(/[^\d]/g, '').length >= min
    ? undefined
    : errorMessage || `Please enter at least ${min} digits`;

/**
 * Field validator.
 * Makes sure that the field value contains no more than specified amount of digits.
 *
 * @param max
 *   Max amount of digits in the field.
 * @param errorMessage
 *   Error message.
 */
export const maxDigits = (max, errorMessage) => (value) =>
  !value || typeof value !== 'string' || value.replace(/[^\d]/g, '').length <= max
    ? undefined
    : errorMessage || `Please enter less than ${max + 1} digits`;

/**
 * Field validator.
 * Validates input email address.
 *
 * It is recommended to use together with "parseSpaceless" parser.
 * see utils/formFieldParsers.js
 *
 * @param errorMessage
 *   Error message.
 */
export const validEmail = (errorMessage) => (value) =>
  !value || EMAIL_EXPRESSION.test(value)
    ? undefined
    : errorMessage || 'Please enter a valid email address, e.g. name@email.com';

/**
 * Field validator.
 * Validates minimum length of the input value (inclusive).
 *
 * @param min
 *   Minimum number of characters required.
 * @param errorMessage
 *   Error message.
 */
export const minLength = (min, errorMessage) => (value) =>
  String(value).length >= min ? undefined : errorMessage || `Please enter at least ${min} letters`;

/**
 * Field validator.
 * Validates input credit card expiry.
 *
 * @param {string} value
 *   Raw form field value.
 */
export const validCreditCardExpiration = (value) =>
  !value || /\d\d\/\d\d/.test(value) ? undefined : 'Please follow mm/yy format';

/**
 * Field validator.
 * Makes sure that the field value equals the given correct value.
 *
 * @param correctValue
 *   Correct value.
 * @param errorMessage
 *   Returned if value is not correct.
 */
export const valueEquals = (correctValue, errorMessage) => (value) =>
  value === correctValue ? undefined : errorMessage;

/**
 * Field validator.
 * Makes sure that the field value doesn't contain a sub-string.
 *
 * @param subValue
 *   A sub-string.
 * @param errorMessage
 *   Returned if value is not correct.
 */
export const valueNotIncludes = (subValue, errorMessage) => (value) => {
  // Allows to handle select-boxes and text-inputs.
  const realValue = value?.value ?? value;

  return !realValue?.toLowerCase().includes(subValue) ? undefined : errorMessage;
};

/**
 * Field validator.
 * Checks that the field has string that could be parsed as valid Date.
 *
 * @param errorMessage
 *   Error message.
 */
export const validDate = (errorMessage) => (value) => {
  const message = errorMessage || 'Invalid format of date';

  return value && isNaN(Date.parse(value)) ? message : undefined;
};

/**
 * Field validator.
 * Checks that the Date field has date after the min date.
 *
 * @param errorMessage
 *   Error message.
 * @param min
 *   min Date
 */
export const minDate = (min, errorMessage) => (value) => {
  const date = new Date();
  const formattedDate = formatDate(date.setDate(min.getDate() - 1));
  const message = errorMessage || `The date should be after the ${formattedDate}`;
  if (!value) {
    return undefined;
  }

  if (isNaN(Date.parse(value))) {
    return 'Invalid format of date';
  }
  return Date.parse(value) < min ? message : undefined;
};

/**
 * Field validator.
 * Checks that the Date field has date after the min date.
 *
 * @param errorMessage
 *   Error message.
 * @param max
 *   max Date
 */
export const maxDate = (max, errorMessage) => (value) => {
  const date = new Date();
  const formattedDate = formatDate(date.setDate(max.getDate() + 1));
  const message = errorMessage || `The date should be before the ${formattedDate}`;

  if (!value) {
    return undefined;
  }

  if (isNaN(Date.parse(value))) {
    return 'Invalid format of date';
  }
  return Date.parse(value) > max ? message : undefined;
};

/**
 * Field validator.
 * Validates Stripe element iframe using metadata passed by Stripe.
 * See https://stripe.com/docs/js/element/events/on_change
 */
export const validStripeElement = (value) => {
  // Normally Stripe exposes error code & message.
  if (value.error && value.error.code && value.error.message) {
    switch (value.error.code) {
      case 'incomplete_expiry':
        return 'Please follow mm/yy format';
      case 'invalid_expiry_year_past':
      case 'invalid_expiry_month_past':
        return 'Should not be in the past';
      case 'incomplete_cvc':
        return 'Please enter at least 3 digits';
      case 'invalid_number':
        return 'This card number is not valid, please double check and try again';
      case 'incomplete_number':
        return 'Please enter full card number';
      default:
        return value.error.message;
    }
  }

  // CW currently doesn't accept American Express.
  if (value.brand === 'amex') {
    return 'Accepted card types are Visa, Visa Debit, Maestro and Mastercard.';
  }

  return undefined;
};

export const fullCardNumberStripeElement = (value) => {
  if (!value.complete) {
    return 'Please enter full card number';
  }
  return undefined;
};

export const fullCVCStripeElement = (value) => {
  if (!value.complete) {
    return 'Please enter at least 3 digits';
  }
  return undefined;
};

export const fullExpireDateStripeElement = (value) => {
  if (!value.complete) {
    return 'Please follow mm/yy format';
  }
  return undefined;
};
