import React from 'react';
import FieldTemplate from '../FieldTemplate';

type Props = {
  label?: string;
  placeholder?: string;
  name: string;
  color?: 'pink' | 'green' | 'white';
  icon?: object;
};

const TextFieldPill = ({
  label = '',
  name,
  color = 'pink',
  placeholder = '',
  icon,
  ...props
}: Props) => (
  <FieldTemplate
    component="input"
    type="text"
    name={name}
    label={label}
    placeholder={placeholder}
    style={`pill-${color} ${label ? 'with-label' : ''}`}
    icon={icon}
    {...props}
  />
);

export default TextFieldPill;
