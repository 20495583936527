import { createElement } from 'react';

import s from './index.module.scss';

type Props = {
  className?: string;
  text: string;
  tag?: string;
  id?: string;
};

const FormattedText = ({ className, text = '', tag = 'div', id = '', ...props }: Props) => {
  return createElement(tag, {
    id,
    className: `${s['formatted-text']} ${className}`,
    'data-component': 'formatted-text',
    ...props,
    dangerouslySetInnerHTML: { __html: text },
  });
};

export default FormattedText;
