import React, { useId } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ChildrenProps } from '../../../types';

import styles from './index.module.scss';

type Props = {
  content?: string;
  children: ChildrenProps;
};

const Tooltip = ({ children, content = '', ...props }: Props) => {
  const id = useId();
  const classes = [styles['tooltip']];

  return (
    <span className={classes.join(' ')}>
      <ReactTooltip {...props} id={id} />
      <span
        className="tooltip__content"
        data-tooltip-content={content}
        data-tooltip-id={id}
        tabIndex={0}
      >
        {children}
      </span>
    </span>
  );
};

export default Tooltip;
