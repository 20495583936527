import React from 'react';
import Switcher from '../../../02_molecules/Switcher';
import FieldTemplate from '../FieldTemplate';

type Props = {
  items: SwitcherFieldItem[];
  onSelect: (value: string) => void;
  default?: string;
  color?: 'pink' | 'turquoise';
  hasSmallLabel?: boolean;
  name: string;
};

type SwitcherFieldItem = {
  label: string;
  value: string;
  labelMobile?: string;
};

type SwitcherFieldAdapterProps = {
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta: object;
};

type onSelectProps = {
  value: string;
};

// Special field adapter for react-final-form library which connects
// <Switcher> component to the form.
const SwitcherFieldAdapter = ({
  input: { value, onChange },
  meta,
  ...rest
}: SwitcherFieldAdapterProps) => (
  <Switcher
    default={value}
    onSelect={({ value: newValue }: onSelectProps) => {
      onChange(newValue);
      return Promise.resolve();
    }}
    {...rest}
  />
);

const SwitcherField = (props: Props) => (
  <FieldTemplate type="switcher" component={SwitcherFieldAdapter} {...props} />
);

export default SwitcherField;
