import React from 'react';
import { Field } from 'react-final-form';
import FieldError from '../FieldError';

import styles from './index.module.scss';

type MutateFormCallback = (name: string, value: string | number) => void;
type RadioButtonSize = 'normal' | 'large';

export type RadioButtonOptions = {
  value?: string | number;
  label?: string;
  className?: string;
  amount?: number;
};

type Props = {
  options: RadioButtonOptions[];
  label?: string;
  name: string;
  size?: RadioButtonSize;
  isBlock?: boolean;
  onKeyDown?: (
    e: React.KeyboardEvent,
    item: RadioButtonOptions,
    mutateFormValue?: MutateFormCallback,
  ) => void;
  mutateFormValue?: MutateFormCallback;
  styling?: 'default' | 'emergency' | 'emergency-black';
};

const RadiosButton = ({
  options,
  label = '',
  name,
  size = 'normal',
  isBlock = true,
  onKeyDown,
  mutateFormValue,
  styling = 'default',
  ...props
}: Props) => {
  const handleKeyDown = (e: React.KeyboardEvent, item: RadioButtonOptions) => {
    if (onKeyDown) {
      onKeyDown(e, item, mutateFormValue);
      return;
    }

    const enter = 13;
    const space = 32;
    if ((e.keyCode === enter || e.keyCode === space) && mutateFormValue && item.value) {
      mutateFormValue(name, item.value);
    }
  };

  const componentCssName = 'field-type-radios-button';

  const classes = [
    styles[componentCssName],
    styles[`${componentCssName}--${styling}`],
    'field-' + name.replace('.', '_'),
  ];

  return (
    <div className={classes.join(' ')} role="group" aria-labelledby={`radios_button_${name}`}>
      <label
        htmlFor={`radios_button_${name}`}
        id={`radios_button_${name}`}
        className={label ? styles[componentCssName + '__label'] : 'd-none'}
      >
        {label}
      </label>

      <div
        className={`${styles[componentCssName + '__radios']} d-flex flex-wrap`}
        data-component="field-type-radios-button__radios"
        role="radiogroup"
        aria-label={name}
      >
        {options.map((item) => (
          <Field
            name={name}
            key={`${name}_${item.value}`}
            render={({ input }) => (
              <label
                htmlFor={`${name}_${item.value}`}
                tabIndex={0}
                className={[
                  styles[componentCssName + '__radios-item'],
                  styles[componentCssName + '__radios-item--' + size],
                  item.value === input.value
                    ? styles[componentCssName + '__radios-item--selected']
                    : '',
                  isBlock ? styles[componentCssName + '__radios-item--media-body'] : '',
                  item.className ? item.className : '',
                ]
                  .filter(Boolean)
                  .join(' ')}
                data-component={
                  item.value === input.value ? componentCssName + '--selected' : componentCssName
                }
                aria-labelledby={`${name}_${item.value}`}
                onKeyDown={(e) => handleKeyDown(e, item)}
                aria-checked={item.value === input.value}
                role="radio"
              >
                <Field
                  component="input"
                  className={`${styles[componentCssName + '__radios-item-input']}`}
                  aria-label={item.label}
                  type="radio"
                  name={name}
                  value={item.value}
                  id={`${name}_${item.value}`}
                  title={item.value}
                  {...props}
                />
                {item.label}
              </label>
            )}
          />
        ))}
      </div>

      <FieldError name={name} />
    </div>
  );
};

export default RadiosButton;
