import { arrayOf, bool, func, shape, string, oneOf } from 'prop-types';

import { PTToggle } from '../../01_atoms/Toggle/prop-types';

const PTToggleGroup = {
  items: arrayOf(shape(PTToggle)).isRequired,
  showDescription: bool,
  defaultRadioValue: string,
  labelPrefix: string,
  isDisabled: bool,
  onToggle: func,
  displayMode: oneOf(['cards', 'icons']),
};

export { PTToggleGroup };
