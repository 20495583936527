import React from 'react';

import { PTSwitcher, PTSwitcherDefaults } from './prop-types';

import CheckMark from '../../../public/static/icons/checkmark.svg';
import styles from './index.module.scss';

class Switcher extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.default || props.item[0].value,
      isLoading: false,
    };
  }

  onSelect = (newValue) => {
    const { isDisabled, isLoading, onSelect } = this.props;
    const { value } = this.state;

    if (isDisabled || isLoading || value === newValue || event.key === 'Tab') {
      return null;
    }

    this.setState({ value: newValue, isLoading: true });

    return onSelect({ value: newValue }).then(() => {
      this.setState({ value: newValue, isLoading: false });
    });
  };

  render() {
    const { items, isDisabled, color, hasSmallLabel } = this.props;
    const { value, isLoading } = this.state;

    return (
      <div
        className={`d-flex justify-content-center ${styles[`color-${color}`]} switcher ${
          styles.switcher
        } ${isDisabled ? styles.disabled : ''} ${isLoading ? styles.loading : ''}`}
      >
        {items.map((item) => (
          <div
            key={`switcher-field-${item.value}`}
            className={`d-flex switcher-field ${styles['switcher-field']} ${
              value === item.value ? `active ${styles.active}` : ''
            } ${hasSmallLabel ? `small ${styles.small}` : ''}`}
            onClick={() => this.onSelect(item.value)}
            onKeyDown={() => this.onSelect(item.value)}
            tabIndex="0"
          >
            <div
              className={`indicator ${styles.indicator} ${
                value === item.value ? 'active' : `not-active ${styles['not-active']}`
              }`}
            >
              {typeof item.indicatorSvg === 'undefined' ? <CheckMark /> : <>{item.indicatorSvg}</>}
            </div>

            {hasSmallLabel ? (
              <>
                <h5 className="d-none d-md-block">{item.label}</h5>
                <h5 className="d-md-none">{item.labelMobile || item.label}</h5>
              </>
            ) : (
              <>
                <div className="d-none d-md-block header-three">{item.label}</div>
                <div className="d-md-none header-three">{item.labelMobile || item.label}</div>
              </>
            )}
          </div>
        ))}
      </div>
    );
  }
}

Switcher.propTypes = PTSwitcher;
Switcher.defaultProps = PTSwitcherDefaults;

export default Switcher;
