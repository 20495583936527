import React from 'react';
import ToggleGroup from '../../../02_molecules/ToggleGroup';
import FieldTemplate from '../FieldTemplate';

type Type = {
  items: ToggleItem[];
  showDescription?: boolean;
  defaultRadioValue?: string;
  labelPrefix?: string;
  isDisabled?: boolean;
  onToggle?: () => void;
  displayMode?: 'cards' | 'icons';
  name: string;
};

type ToggleItem = {
  value: string;
  label: string;
  description?: string;
  descriptionTitle?: string;
  showDescription?: boolean;
  onToggle?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
  canToggle?: boolean;
  icon?: () => void;
};

type ToggleGroupFieldAdapterProps = {
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta: object;
  items: ToggleGroupFieldAdapterItem[];
};

type ToggleGroupFieldAdapterItem = {
  value: string;
};

type validateValueCallback = (
  valueFromForm: string,
  items: { value: string }[],
  callback: (value: string) => void,
) => string | undefined;

type ToggleGroupOnToggle = {
  value: string;
};

// Special field adapter for react-final-form library which connects
// <ToggleGroup> component to the form.
const ToggleGroupFieldAdapter = ({
  input: { value, onChange },
  meta,
  ...rest
}: ToggleGroupFieldAdapterProps) => {
  const validateValue: validateValueCallback = (valueFromForm, items, callback) => {
    if (!items.length) return undefined;

    // If current value is outside of available items (edge case),
    // then use the first item as a value.
    let validItem = items.find((item) => item.value === valueFromForm);
    if (validItem === undefined) {
      [validItem] = items;
    }
    if (validItem.value !== valueFromForm) {
      // Inform the form about this change.
      callback(validItem.value);
    }

    return validItem.value;
  };

  return (
    // @ts-expect-error ToggleGroup still not compatible with TS.
    <ToggleGroup
      defaultRadioValue={validateValue(value, rest.items, onChange)}
      onToggle={({ value: newValue }: ToggleGroupOnToggle) => onChange(newValue)}
      {...rest}
    />
  );
};

const ToggleGroupField = (props: Type) => (
  <FieldTemplate type="toggle-group" component={ToggleGroupFieldAdapter} {...props} />
);

export default ToggleGroupField;
