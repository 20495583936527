import React from 'react';
import FieldTemplate from '../FieldTemplate';

type Props = {
  onFocus?: () => void;
  readOnly?: boolean;
  /**
   * Field label.
   */
  label?: string;
  /**
   * Field name.
   */
  name: string;
  /**
   * Description text below the field.
   */
  info?: string;
  /**
   * Description text below the field when the pattern is not matching.
   */
  infoOnPatternNotMatched?: string;
  /**
   * Icon component.
   */
  icon?: object;
  /**
   * Overlay text label on the right side of the field.
   */
  textIcon?: string;
  /**
   * Field placeholder.
   */
  placeholder?: string;
  isRequired?: boolean;
  tooltip?: string;
  /**
   * If provided, text field will display infoOnPatternNotMatched text when the input doesn't match the pattern.
   */
  pattern?: string;
};

const defaultProps = {
  info: '',
  infoOnPatternNotMatched: '',
  textIcon: '',
  placeholder: '',
  isRequired: false,
  tooltip: '',
};

const TextField = ({ label = '', name, ...componentProps }: Props) => {
  const props = {
    ...defaultProps,
    ...componentProps,
  };

  return (
    <FieldTemplate
      component="input"
      type="text"
      name={name}
      label={label}
      style="normal"
      {...props}
    />
  );
};

export default TextField;
