import React from 'react';
import { useForm, useFormState } from 'react-final-form';

import styles from './index.module.scss';

type Props = {
  name: string;
  info?: string;
  pattern?: string;
};

const FieldInfo = ({ name, info = '', pattern = '' }: Props) => {
  const form = useForm();
  const { error, touched, value } = form.getFieldState(name) || {};
  const { submitFailed } = useFormState() || {};

  if (!info) {
    return null;
  }

  const infoElement = (
    <div
      className={styles['field-info']}
      data-component="field-info"
      dangerouslySetInnerHTML={{ __html: info }}
    />
  );
  const showError = error && (submitFailed || (touched && value));
  if (!pattern && !showError) {
    return infoElement;
  }

  return !(showError && touched) ? infoElement : null;
};

export default FieldInfo;
