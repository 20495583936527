import React from 'react';
import styles from './index.module.scss';

type Props = {
  className?: string;
  color?: 'white' | 'turquoise';
};

const Spinner = ({ className = '', color = 'white' }: Props) => (
  <span className={`spinner ${styles.spinner} ${className} ${styles[color]}`}>
    <span />
    <span />
    <span />
    <span />
  </span>
);

export default Spinner;
