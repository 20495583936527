import { arrayOf, bool, func, shape, string, node, oneOf } from 'prop-types';

const PTSwitcher = {
  items: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
      labelMobile: string,
      indicatorSvg: node,
    }),
  ).isRequired,
  onSelect: func,
  default: string,
  color: oneOf(['pink', 'turquoise']),
  hasSmallLabel: bool,
};

const PTSwitcherDefaults = {
  onSelect: () => {},
  default: null,
  color: 'pink',
  hasSmallLabel: false,
};

export { PTSwitcher, PTSwitcherDefaults };
