import React from 'react';
import { Field, useForm } from 'react-final-form';
import FieldError from '../FieldError';
import FieldInfo from '../FieldInfo';
import { ChildrenProps } from '../../../../types';

function isChecked(
  currentValue: CheckboxOptionValue | CheckboxOptionValue[],
  value: CheckboxOptionValue,
) {
  if (Array.isArray(currentValue)) {
    return currentValue.includes(value);
  }
  // For single checkboxes the value is boolean.
  return currentValue;
}

type Props = {
  label?: string;
  name: string;
  withBackground?: boolean;
  onKeyDown?: (e: React.KeyboardEvent, value: string | boolean, setValue: () => void) => void;
  isRequired?: boolean;
  info?: string;
  options: CheckboxOptions[];
};

type CheckboxOptions = {
  value: CheckboxOptionValue;
  label: ChildrenProps;
  labelON?: string;
  icon?: ChildrenProps;
};

type CheckboxOptionValue = string | boolean;

const CheckboxesField = ({
  options,
  label = '',
  name,
  info,
  withBackground = false,
  onKeyDown,
  isRequired = false,
  ...props
}: Props) => {
  const { mutators } = useForm();
  const addOrRemove = (array: CheckboxOptionValue[], item: CheckboxOptionValue) => {
    const exists = array.includes(item);

    if (exists) {
      return array.filter((c) => {
        return c !== item;
      });
    } else {
      const result = [...array];
      result.push(item);
      return result;
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent,
    value: CheckboxOptionValue,
    currentValue: CheckboxOptionValue[],
  ) => {
    if (!mutators.setValue) {
      // TODO: implement accessible checkboxes in the styleguide.
      return;
    }

    if (onKeyDown) {
      onKeyDown(e, value, mutators.setValue);
      return;
    }

    const enter = 13;
    const space = 32;

    if ((e.keyCode === enter || e.keyCode === space) && mutators.setValue) {
      e.preventDefault();
      const newCurrentValue = addOrRemove(currentValue, value);
      mutators.setValue(name, newCurrentValue);
    }
  };
  return (
    <Field name={name} subscription={{ error: true, touched: true }}>
      {({ meta: { error, touched } }) => (
        <div
          className={`field-type-checkboxes field-${name} ${error && touched ? 'error' : ''}`}
          role="group"
          aria-labelledby={label ? `checkboxes_${name}` : ''}
        >
          {label && (
            <label htmlFor={`checkboxes_${name}`} id={`checkboxes_${name}`}>
              {label}
              <span className="validation">{isRequired ? ' (required)' : ' (optional)'}</span>
            </label>
          )}

          <div className="checkbox-values">
            {options.map((item) => (
              <Field
                name={name}
                key={`${name}_${item.value}`}
                render={({ input }) => (
                  <div
                    className={`checkbox-item${withBackground ? ' with-background' : ''}${
                      typeof item.icon !== 'undefined' ? ' with-icon' : ''
                    }${isChecked(input.value, item.value) ? ' selected' : ''}`}
                  >
                    {typeof item.icon !== 'undefined' && <div className="icon">{item.icon}</div>}

                    <label
                      htmlFor={`${name}_${item.value}`}
                      tabIndex={0}
                      onKeyDown={(e) => handleKeyDown(e, item.value, input.value)}
                    >
                      <Field
                        component="input"
                        type="checkbox"
                        name={name}
                        id={`${name}_${item.value}`}
                        value={options.length === 1 && item.value === true ? undefined : item.value}
                        data-analytics={'opt-in'}
                        {...props}
                      />

                      <span className="indicator" />

                      <div className="label">
                        <span>
                          {isChecked(input.value, item.value) &&
                            typeof item.labelON !== 'undefined' &&
                            item.labelON}
                        </span>
                        <span>
                          {isChecked(input.value, item.value) &&
                            typeof item.labelON === 'undefined' &&
                            item.label}
                        </span>
                        <span>{!isChecked(input.value, item.value) && item.label}</span>
                      </div>
                    </label>
                  </div>
                )}
              />
            ))}
          </div>

          <FieldInfo name={name} info={info} />
          <FieldError name={name} />
        </div>
      )}
    </Field>
  );
};

export default CheckboxesField;
