import React from 'react';
import Textarea from 'react-textarea-autosize';
import FieldTemplate from '../FieldTemplate';

type Props = {
  label?: string;
  name: string;
  info?: string;
  isRequired?: boolean;
};

type TextAreaAdapterProps = {
  input: {
    value: string;
    onChange: (value: string) => void;
    onFocus: () => void;
    onBlur: () => void;
  };
  meta: object;
};

const TextAreaAdapter = ({
  input: { value, onChange, onFocus, onBlur },
  meta,
  ...rest
}: TextAreaAdapterProps) => (
  <Textarea
    value={value}
    onChange={(event) => onChange(event.target.value)}
    onFocus={onFocus}
    onBlur={onBlur}
    {...rest}
  />
);

const TextArea = ({ label = '', info = '', isRequired = false, name, ...props }: Props) => (
  <FieldTemplate
    component={TextAreaAdapter}
    type="text"
    minRows={3}
    name={name}
    label={label}
    info={info}
    isRequired={isRequired}
    style="normal"
    {...props}
  />
);

export default TextArea;
