import React from 'react';
import { PTToggle } from '../prop-types';

import s from './index.module.scss';

const CardToggle = ({
  isActive = false,
  isDisabled = false,
  onToggle = () => {},
  label,
  showDescription = true,
  description = '',
}) => {
  const handleClick = (e) => {
    if (e.code !== 'Tab') {
      onToggle();
    }
  };
  return (
    <div
      className={`${s['card-toggle']} ${isActive ? s['card-toggle--active'] : ''} ${isDisabled ? s['card-toggle--disabled'] : ''}`}
      onClick={onToggle}
      onKeyDown={handleClick}
      tabIndex="0"
    >
      <div
        className={`${s['card-toggle__label']} ${showDescription && description ? s['card-toggle__label-description'] : ''} `}
      >
        {label}
      </div>
    </div>
  );
};

CardToggle.propTypes = PTToggle.isRequired;

export default CardToggle;
