import React, { useRef, useEffect } from 'react';
import FieldTemplate from '../FieldTemplate';
import Button from '../../Button';
import ReactSignatureCanvas from 'react-signature-canvas';
import styles from './index.module.scss';

type Props = {
  label?: string;
  name: string;
  info?: string;
};

type SignatureFieldAdapterProps = {
  input: {
    value: string;
    onChange: (value: string) => void;
  };
  meta: object;
};

const SignatureField = ({ label = '', info = '', name, ...props }: Props) => {
  const signatureCanvas = useRef<ReactSignatureCanvas>() as { current: ReactSignatureCanvas };

  const SignatureFieldAdapter = ({
    input: { value, onChange },
    meta,
    ...rest
  }: SignatureFieldAdapterProps) => {
    useEffect(() => {
      const canvas = signatureCanvas.current.getCanvas();
      const parent = canvas.parentNode as HTMLElement;
      canvas.width = parent.offsetWidth;
      canvas.height = Math.round(parent.offsetWidth / 2);
      // On high DPI screens the library tries to calculate the ratio badly. Fix it to 1.
      signatureCanvas.current?.fromDataURL(value, { ratio: 1 });
    });
    return (
      <>
        <ReactSignatureCanvas
          ref={signatureCanvas}
          canvasProps={{ width: 400, height: 200, className: styles.sigCanvas }}
          onEnd={() => onChange(signatureCanvas.current.toDataURL('image/png'))}
          {...rest}
        />
        <Button
          type="secondary"
          size="small"
          onClick={(e) => {
            e.preventDefault();
            signatureCanvas.current.clear();
            onChange('');
          }}
          className={styles.clearCanvas}
        >
          Clear signature
        </Button>
      </>
    );
  };

  return (
    <FieldTemplate
      component={SignatureFieldAdapter}
      type="text"
      name={name}
      label={label}
      info={info}
      style="normal"
      {...props}
    />
  );
};

export default SignatureField;
