import React from 'react';

import { PTToggleGroup } from './prop-types';
import Toggle from '../../01_atoms/Toggle';

/**
 * A group of Toggle buttons that function as a radio button group and
 * control its container & description.
 * @extends React
 */
class ToggleGroup extends React.Component {
  constructor(props) {
    super(props);

    const defaultRadioValue = props.defaultRadioValue || props.items[0].value;

    this.state = {
      radioValue: defaultRadioValue,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.overrideValue) {
      return { ...state, radioValue: props.overrideValue };
    }
    return null;
  }

  onToggle =
    (position) =>
    ({ value }) => {
      const { isDisabled = false, onToggle = () => {} } = this.props;

      if (isDisabled) {
        return null;
      }

      this.setState({ radioValue: value });

      return onToggle({ value, position });
    };

  render() {
    const {
      labelPrefix = '',
      items,
      showDescription = true,
      displayMode = 'cards',
      id,
    } = this.props;
    const { radioValue } = this.state;

    return (
      <div
        className={`toggle-group toggle-group-${displayMode}`}
        role="group"
        aria-labelledby={`${id}_group_head`}
      >
        <div id={`${id}_group_head`} className="d-none">
          {id === 'payment_method'
            ? `Payment methods / group of ${items.length} items`
            : `Group of ${items.length} items`}
        </div>
        <div role="radiogroup">
          {items.map((toggle, index) => (
            <Toggle
              key={toggle.value}
              label={`${labelPrefix}${toggle.value}`}
              {...toggle}
              {...this.props}
              {...this.state}
              onToggle={this.onToggle(index + 1)}
              displayMode={displayMode}
            />
          ))}
        </div>

        {showDescription &&
          items.map(
            (toggle) =>
              (toggle.description || toggle.descriptionTitle) &&
              radioValue === toggle.value && (
                <div
                  key={`${toggle.description}_${toggle.descriptionTitle}`}
                  className="description"
                >
                  {toggle.descriptionTitle && <div>{toggle.descriptionTitle}</div>}
                  {toggle.description}
                </div>
              ),
          )}
      </div>
    );
  }
}

ToggleGroup.propTypes = PTToggleGroup.isRequired;

export default ToggleGroup;
