import React, { Dispatch, SetStateAction, useState } from 'react';
import { Field, useFormState, useForm, SupportedInputs } from 'react-final-form';
import FieldError from '../FieldError';
import FieldInfo from '../FieldInfo';
import ErrorIcon from '../../../../public/static/icons/error.svg';
import QuestionIcon from '../../../../public/static/icons/question-icon.svg';
import CheckmarkIcon from '../../../../public/static/icons/checkmark.svg';
import Tooltip from '../../Tooltip';
import { ChildrenProps } from '../../../../types';

type Props = {
  component: FieldTemplateComponent;
  components?: {
    [key: string]: FieldTemplateComponent;
  };
  children?: ChildrenProps;
  type: string;
  // Field label.
  label?: string;
  // Field name.
  name: string;
  // Additional CSS classes. If style="normal", then the CSS class will be field-style-normal.
  style?: string;
  // Description text below the field.
  info?: string;
  // Description text below the field when the pattern is not matching.
  infoOnPatternNotMatched?: string;
  onLabelClick?: () => void;
  // Icon component.
  icon?: object;
  // Overlay text label on the right side of the field.
  textIcon?: string;
  // Field placeholder.
  placeholder?: string;
  isRequired?: boolean;
  tooltip?: string;
  // If provided, text field will display infoOnPatternNotMatched text when the input doesn't match the pattern.
  pattern?: string;
  // Used in TextArea component.
  minRows?: number;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
type FieldTemplateComponent = React.ComponentType<any> | SupportedInputs;

import styles from './index.module.scss';

const FieldTemplate = ({
  label = '',
  name,
  component,
  children,
  type,
  style = '',
  info = '',
  infoOnPatternNotMatched = '',
  onLabelClick = () => {},
  icon,
  textIcon = '',
  placeholder = '',
  isRequired = false,
  tooltip = '',
  pattern,
  ...props
}: Props) => {
  const [typedCharacterInPattern, setTypedCharacterInPattern] = useState(true);
  const form = useForm();
  const formState = useFormState();
  const fieldState = form.getFieldState(name);
  const { error, touched, active, value } = fieldState || {};

  const checkKeyAgainstPattern = (
    ev: React.KeyboardEvent,
    setTypedCharacterInPattern: Dispatch<SetStateAction<boolean>>,
    pattern: string,
  ) => {
    const regex = new RegExp(pattern);
    setTypedCharacterInPattern(regex.test(ev.key));
  };

  const classes = [styles['field'], `field-type-${type}`, `field-${name}`];

  // Show error when there's a validation error in the field and
  // either the form was submitted or the field has some value and was
  // focused in and then out.
  const showError = error && (formState.submitFailed || (touched && value));

  if (style.length) {
    classes.push(`field-style-${style}`);
  }

  if (icon) {
    classes.push('with-icon');
  }

  if (showError) {
    classes.push('error');
  }

  if (active) {
    classes.push('active');
  }

  if (value) {
    classes.push('has-value');
  }

  return (
    <div className={classes.join(' ')} data-component="field-template">
      {(label || tooltip) && (
        <label className={styles['field__label']} htmlFor={name} onClick={onLabelClick}>
          {label}
          {!!label && (
            <span className="validation">{isRequired ? ' (required)' : ' (optional)'}</span>
          )}
          {tooltip && (
            <Tooltip content={tooltip}>
              <QuestionIcon className="question-icon" />
            </Tooltip>
          )}
        </label>
      )}
      <div style={{ position: 'relative' }}>
        {!!icon && icon}

        {/* This renders the field element input */}
        <Field
          name={name}
          id={name}
          component={component}
          type={type}
          aria-label={label || name}
          aria-required={isRequired ? 'true' : 'false'}
          {...props}
          placeholder={placeholder}
          onKeyPress={
            pattern
              ? (ev: React.KeyboardEvent) =>
                  checkKeyAgainstPattern(ev, setTypedCharacterInPattern, pattern)
              : null
          }
        >
          {children}
        </Field>

        {showError && (
          <div
            className={styles['field__right-icon-container']}
            data-component={'field-right-icon'}
          >
            <ErrorIcon className={styles['field__right-icon-container__error-icon']} />
          </div>
        )}

        {!error && touched && value && (
          <div
            className={styles['field__right-icon-container']}
            data-component={'field-right-icon'}
          >
            <CheckmarkIcon className={styles['field__right-icon-container__checkmark-icon']} />
          </div>
        )}

        {!!textIcon && <div className={styles['field__text-icon']}>{textIcon}</div>}

        <FieldInfo
          name={name}
          info={typedCharacterInPattern ? info : infoOnPatternNotMatched}
          pattern={pattern}
        />

        <FieldError name={name} />
      </div>
    </div>
  );
};

export default FieldTemplate;
