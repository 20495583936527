import { bool, func, string } from 'prop-types';

const PTToggle = {
  value: string.isRequired,
  label: string.isRequired,
  description: string,
  descriptionTitle: string,
  showDescription: bool,
  onToggle: func,
  isActive: bool,
  isDisabled: bool,
  canToggle: bool,
  icon: func,
};

export { PTToggle };
