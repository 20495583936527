import React from 'react';
import PropTypes from 'prop-types';

import Select, { components } from 'react-select';
import FieldTemplate from '../../FieldTemplate';
import DropdownIcon from '../../../../../public/static/icons/navigation-chevron-down.svg';
import ClearIcon from '../../../../../public/static/icons/close-header-main.svg';

class SelectFieldStandalone extends React.Component {
  ClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => (
    <div {...restInnerProps} ref={ref} className="clear">
      Clear Filters <ClearIcon />
    </div>
  );

  // Custom template for "SelectContainer" component of react-select library.
  SelectContainer = ({ children, hasValue, selectProps, ...props }) => {
    const classes = [];

    if (hasValue) {
      classes.push('has-value');
    }

    if (selectProps.menuIsOpen) {
      classes.push('opened');
    }

    return (
      <components.SelectContainer {...props} className={classes.join(' ')}>
        {children}
      </components.SelectContainer>
    );
  };

  // Special field adapter for react-final-form library which connects
  // react-select component to the form.
  SelectFieldAdapter = ({ input: { value, onChange, onFocus, onBlur }, meta, ...rest }) => (
    <Select
      value={value}
      onChange={(selectedOption) => onChange(selectedOption)}
      onFocus={onFocus}
      onBlur={onBlur}
      blurInputOnSelect
      {...rest}
    />
  );

  render() {
    const { isClearable = false, label = '', ...props } = this.props;

    return (
      <FieldTemplate
        {...props}
        placeholder={label}
        label=""
        component={this.SelectFieldAdapter}
        type="select"
        components={{
          SelectContainer: this.SelectContainer,
          DropdownIndicator: () => <DropdownIcon />,
          ClearIndicator: this.ClearIndicator,
        }}
        isClearable={isClearable}
        className="select"
        classNamePrefix="select"
        /* eslint-disable-next-line react/style-prop-object */
        style={`standalone ${isClearable ? 'clearable' : ''}`}
      />
    );
  }
}

SelectFieldStandalone.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
};

/* eslint-disable-next-line react/forbid-foreign-prop-types */
export const { propTypes } = SelectFieldStandalone;

export default SelectFieldStandalone;
