// Helper function lock users who use keyboard navigation to navigate inside CookiePopup.
const keyboardFocusWithinElement = (e, selector, includeElementsWithTabIndex) => {
  if (e.keyCode === 9) {
    let clickableSelectors = 'input,button,select,textarea,a';
    if (includeElementsWithTabIndex) {
      clickableSelectors += ',*[tabindex="0"]';
    }
    const focusable = document.querySelector(selector).querySelectorAll(clickableSelectors);

    if (focusable.length) {
      let first = focusable[0];
      let last = focusable[focusable.length - 1];
      let shift = e.shiftKey;
      if (shift) {
        if (e.target === first) {
          // shift-tab pressed on first input in dialog
          e.preventDefault();
          last.focus();
        }
      } else {
        if (e.target === last) {
          // tab pressed on last input in dialog
          e.preventDefault();
          first.focus();
        }
      }
    }
  }
};

export default keyboardFocusWithinElement;
