import { Form, FormSpy, Field } from 'react-final-form';

import FieldTemplate from './FieldTemplate';
import TextField from './TextField';
import TextFieldPill from './TextFieldPill';
import TextArea from './TextArea';
import FieldError from './FieldError';
import FieldInfo from './FieldInfo';
import RadiosField from './RadiosField';
import RadiosButton from './RadiosButton';
import CheckboxesField from './CheckboxesField';
import SwitcherField from './SwitcherField';
import ToggleGroupField from './ToggleGroupField';
import SelectField from './SelectField';
import SelectFieldAsync from './SelectField/SelectFieldAsync';
import SelectFieldStandalone from './SelectField/SelectFieldStandalone';
import SignatureField from './SignatureField';

export {
  FieldTemplate,
  TextField,
  TextFieldPill,
  TextArea,
  SelectField,
  SelectFieldStandalone,
  SelectFieldAsync,
  FieldError,
  RadiosField,
  RadiosButton,
  CheckboxesField,
  SwitcherField,
  ToggleGroupField,
  FieldInfo,
  SignatureField,
};

// In the past when we had separate Styleguide and CNET apps we had to import
// 'react-final-form' from this file only.
// It's not a requirement anymore, but we keep the export below for backward compatibility.
export { Form, FormSpy, Field };
