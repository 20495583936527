// TODO: add support of other formats or use date-fns package.
// Currently date-fns can't parse MMM/LLL formats.

export function formatDate(date, format = 'MMM D, Y', locale = 'en') {
  if (format !== 'MMM D, Y') {
    console.error(`Couldn't format date. Unexpected ${format} format.`);
  }
  const dateObject = new Date(date);
  return `${dateObject.toLocaleDateString('en-IE', {
    month: 'short',
  })} ${dateObject.toLocaleDateString('en-IE', {
    day: 'numeric',
  })}, ${dateObject.toLocaleDateString('en-IE', { year: 'numeric' })}`;
}

export function parseDate(str) {
  return new Date(Date.parse(str));
}
