import React from 'react';

import { PTToggle } from './prop-types';
import CardToggle from './CardToggle';
import IconToggle from './IconToggle';

/**
 * A toggleable block with amount and currency that can also function as a radio button.
 * TODO - extend to make unspecific to data, i.e. remove currency, add data prop.
 * @extends React
 */
class Toggle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggled: false,
    };

    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
    const { canToggle = false, value, radioValue } = this.props;

    // make sure the controlling radiogroup is handed the correct state
    if (radioValue === value && !canToggle) {
      this.onToggle();
    }
  }

  onToggle = () => {
    const { canToggle = false, value, isDisabled = false, onToggle = () => {} } = this.props;

    if (isDisabled) {
      return null;
    }

    // can also toggle arbitrarily if not part of a radio group
    if (canToggle) {
      this.setState((prevState) => ({ isToggled: !prevState.isToggled }));
    }

    return onToggle({ value });
  };

  render() {
    const { value, radioValue, displayMode } = this.props;
    const { isToggled } = this.state;
    const isActive = isToggled || value === radioValue;
    const Component = displayMode === 'cards' ? CardToggle : IconToggle;

    return (
      <Component {...this.props} {...this.state} onToggle={this.onToggle} isActive={isActive} />
    );
  }
}

Toggle.propTypes = PTToggle.isRequired;

export default Toggle;
